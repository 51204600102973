import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PubListPage = ({ pageContext }) => {
  const { pubs } = pageContext

  return (
    <Layout>
      <SEO title={`Pubs to Let`} />

      <div className="flex flex-wrap mt-2 bg-white border border-gray-300">
        <div className="w-full p-4 md:p-10">
          <h1 className={`pb-4 border-b border-gray-300 mb-8`}>Pubs to Let</h1>

          <p>
            You can view our current pubs to let below. If you can't find what
            you're looking for, please <Link to="/contact-us/">contact us</Link>{" "}
            for more information and regular updates.
          </p>

          {pubs.map(pub => {
            return (
              <div className="flex flex-wrap p-6 border border-gray-300 mb-4">
                <div className="w-full lg:w-1/3 p-4">
                  <img
                    src={pub.featuredImage.src}
                    alt={pub.featuredImage.alt}
                  />
                </div>
                <div className="w-full lg:w-2/3 p-4">
                  <div className="md:flex md:justify-between">
                    <h2>{pub.name}</h2>

                    <div className="md:text-right">
                      <p className="mb-1 md:mb-0">
                        <span>Potential Turnover:</span>
                        <strong className={`font-bold text-xl ml-2`}>
                          £{pub.potentialTurnover.toLocaleString()}
                        </strong>
                      </p>
                      <p className="md:mb-0">
                        <span>Guide rent per annum:</span>{" "}
                        <strong className={`text-lg font-semibold`}>
                          £{pub.rent.toLocaleString()}
                        </strong>
                      </p>
                    </div>
                  </div>

                  <p
                    className={`border-t border-b border-gray-300 py-4 my-6 flex gap-4 md:py-8 mb-8`}
                  >
                    <a
                      href={`http://www.google.com/maps/place/${pub.latitude},${pub.longitude}`}
                      target="_blank"
                      rel="noreferrer"
                      className={`flex items-center text-base no-underline`}
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        className={`w-6 h-6 mr-4`}
                      >
                        <path
                          fill-rule="evenodd"
                          d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span>{pub.address}</span>
                    </a>
                  </p>

                  <Link
                    to={`/pubs-to-let/${pub.slug}/`}
                    className="bg-[#fd1c36] px-6 py-3 text-white"
                  >
                    View More Details
                  </Link>
                </div>
              </div>
            )
          })}

          {pubs.length === 0 && <p>There are currently no pubs to let.</p>}
        </div>
      </div>
    </Layout>
  )
}

export default PubListPage
